import PropTypes, { string } from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
  value: string,
  error: PropTypes.any,
  disabled: PropTypes.bool,
};

export default function RHFTextField({ name, value, error, helperText, disabled, ...other }) {
  // const { control } = useFormContext();

  return (
    <TextField
      disabled={disabled}
      fullWidth
      value={value}
      error={error?.length > 0 ?? false}
      helperText={error ?? helperText}
      {...other}
    />
    // <Controller
    //   name={name}
    //   control={control}
    //   render={({ field, fieldState: { error } }) => (
    //     <TextField
    //       {...field}
    //       fullWidth
    //       value={field.value}
    //       error={!!error}
    //       helperText={error ? error?.message : helperText}
    //       {...other}
    //     />
    //   )}
    // />
  );
}
