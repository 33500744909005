// routes
import { PATH_AUTH } from '../routes/paths';
// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------
// import api from '../api';
import API from '../api';

export function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  // console.log(JSON.parse(jsonPayload));

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now();
  console.log(`${decoded.exp * 1000} > ${currentTime}: ${decoded.exp > currentTime}`);
  return decoded.exp * 1000 > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
  // eslint-disable-next-line prefer-const

  const intervalidId = setInterval(() => {
    // console.log(`scheduled func called ${intervalidId}`);
    const currentTime = Date.now();
    const lastRequestTime = Number(sessionStorage.getItem('lastRequestTime'));
    if (lastRequestTime && lastRequestTime < currentTime - 10 * 60 * 1000) {
      console.log(`no request to the server in the last 10mins. logout user!`);
      localStorage.removeItem('accessToken');
      clearInterval(intervalidId);
      window.location.href = PATH_AUTH.login;
    }
    API.get('users/auth-refresh')
      .then((resp) => {
        const accessToken2 = resp.headers.authorization;
        localStorage.setItem('accessToken', accessToken2);
        axios.defaults.headers.common.Authorization = { accessToken2 };
      })
      .catch(() => {
        localStorage.removeItem('accessToken');
        window.location.href = PATH_AUTH.login;
        clearInterval(intervalidId);
      });
    // window.location.href = PATH_AUTH.login;
  }, 9 * 60 * 1000);

  localStorage.setItem('schedularId', intervalidId);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);

    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.headers.common.Authorization = { accessToken };

    const { exp } = jwtDecode(accessToken.replace('Bearer ', ''));
    // This function below will handle when token is expired

    const schedularId = Number(localStorage.getItem('schedularId'));
    if (schedularId) {
      clearInterval(schedularId);
    }

    tokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};
