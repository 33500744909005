export function getColorFromStatus(status) {
  return (
    (status === 'SUCCESS' && 'success') ||
    (status === 'SUCCESSFUL' && 'success') ||
    (status === 'PENDING' && 'warning') ||
    (status === 'FAILED' && 'error') ||
    (status === 'ACTIVE' && 'success') ||
    (status === 'INACTIVE' && 'error') ||
    (status === 'EXPIRED' && 'error') ||
    (status === 'NEW' && 'warning') ||
    (status === 'DELETED' && 'error') ||
    (status === false && 'error') ||
    (status === true && 'success') ||
    'primary'
  );
}
