import PropTypes from 'prop-types';
// @mui
import {Card, CardHeader, LinearProgress, Stack, Typography} from '@mui/material';
// utils
import {fPercent} from '../../../../utils/formatNumber';

// ----------------------------------------------------------------------

RegistrationsOverview.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function RegistrationsOverview({ title, subheader, data, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Stack spacing={4} sx={{ p: 3 }}>
        {data.map((progress) => (
          <ProgressItem key={progress.label} progress={progress} />
        ))}
      </Stack>
    </Card>
  );
}

// ----------------------------------------------------------------------

ProgressItem.propTypes = {
  progress: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    percentage: PropTypes.number,
  }),
};

function ProgressItem({ progress }) {
  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
          {progress.label}
        </Typography>
        <Typography variant="subtitle2">{progress.value}</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          &nbsp;({fPercent(progress.percentage*100)})
        </Typography>
      </Stack>

      <LinearProgress
        variant="determinate"
        value={progress.percentage*100}
        color={
          (progress.label === 'Data' && 'info') ||
          (progress.label === 'Non Data' && 'warning') ||
          'primary'
        }
      />
    </Stack>
  );
}
