import PropTypes from 'prop-types';
// import ReactApexChart from 'react-apexcharts';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../../utils/formatNumber';
// components
import Chart, { useChart } from '../../../../components/chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 425;

const LEGEND_HEIGHT = 60;

const StyledChart = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(1),
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT,
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    // position: 'relative !important', //this is the cause of the legend not displaying
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

CurrentSubscriptionsPieChart.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.string,
  type: PropTypes.string,
};

export default function CurrentSubscriptionsPieChart({ title, subheader, chart,type="donut", ...other }) {
  const theme = useTheme();

  const { colors, series, options } = chart;

  const chartSeries = series.map((i) => i.value);
  const total = chartSeries.reduce((partialSum, a) => partialSum + a, 0);

  const chartOptions = useChart({
    // chart: {
    //   sparkline: {
    //     enabled: true,
    //   },
    // },
    colors,
    labels: series.map((i) => i.label),
    // stroke: {
    //   colors: [theme.palette.background.paper],
    // },
    legend: {
      floating: true,
      horizontalAlign: 'center',
    },
    dataLabels: {
      enabled: true,
      fontSize: '24px',
      color: 'black',
      dropShadow: { enabled: false },
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (value) => fNumber(value),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    // fill: {
    //   type: 'pattern',
    //   opacity: 1,
    //   pattern: {
    //     enabled: false,
    //       style: ['verticalLines', 'squares', 'horizontalLines', 'circles','slantedLines'],
    //   },
    // },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '30%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          name: {
            show: true,
          },
          value: {
            formatter: (value, opts) => `${value}`,
            show: true,
          },
          total:{
            show: type!=="radialBar",
          }
        },
      }
    },
    ...options,
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <StyledChart dir="ltr">
        <Chart type={type} series={chartSeries} options={chartOptions} height={350} />
      </StyledChart>
    </Card>
  );
}
