import PropTypes from 'prop-types';
import * as React from 'react';

import { Button, InputAdornment, Stack, TextField } from '@mui/material';
// components
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../../components/iconify';
import API from '../../../../api';

// ----------------------------------------------------------------------

const INPUT_WIDTH = 160;

InvoiceTableToolbar.propTypes = {
  isFiltered: PropTypes.bool,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onResetFilter: PropTypes.func,
  filterService: PropTypes.string,
  onFilterEndDate: PropTypes.func,
  onFilterService: PropTypes.func,
  onFilterStartDate: PropTypes.func,
  filterEndDate: PropTypes.instanceOf(Date),
  filterStartDate: PropTypes.instanceOf(Date),
  optionsService: PropTypes.arrayOf(PropTypes.string),
  onSearch: PropTypes.func,
  onLoading: PropTypes.func,
  customers: PropTypes.func,
  statusOptions: PropTypes.array,
};

export default function InvoiceTableToolbar({
  isFiltered,
  filterName,
  onFilterName,
  filterEndDate,
  filterService,
  onResetFilter,
  optionsService,
  filterStartDate,
  onFilterService,
  onFilterEndDate,
  onFilterStartDate,
  onSearch,
  onLoading,
  customers,
  statusOptions,
}) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const searchCustoimer = async (data) => {
    try {
      const result = [];
      if (data) {
        onLoading(true);
        setIsSubmitting(true);
        API.get(`customers/msisdn/${data}`)
          .then((response) => {
            setIsSubmitting(false);
            onLoading(false);
            if (response.data.status === '000') {
              // console.log(Object.entries(response.data.details));
              result.push(response.data.details);
              customers(result);
              console.log(result);
            } else {
              // setErrorMessage(response.data.message);
              // setErrorOpen(true);
            }
          })
          .catch(() => {
            onLoading(false);
            // setErrorMessage('Unable to process request. Please try agian or contact system administrator');
            // setErrorOpen(true);
          });
      }
      // setSubscription(subscriptions);
    } catch (e) {
      onLoading(false);
      // setSubscription(subscriptions);
    }
  };
  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{ px: 2.5, py: 3 }}
    >
      <TextField
        fullWidth
        sx={{
          maxWidth: { md: 300 },
        }}
        value={filterName}
        onChange={onFilterName}
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />
      <LoadingButton
        fullWidth
        onClick={() => {
          searchCustoimer(filterName);
        }}
        sx={{
          maxWidth: { md: 180 },
        }}
        variant="contained"
        loading={isSubmitting}
      >
        Advanced Search
      </LoadingButton>

      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={onResetFilter}
          startIcon={<Iconify icon="eva:trash-2-outline" />}
        >
          Clear
        </Button>
      )}
    </Stack>
  );
}
