import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="200%" height="200%" viewBox="0 0 512 512">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>

        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          {/* <path
            fill="url(#BG1)"
            d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
          />
          <path
            fill="url(#BG2)"
            d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
          />
          <path
            fill="url(#BG3)"
            d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
          /> */}

          <path
            fill="#FFFEFE"
            opacity="1.000000"
            stroke="none"
            d="
M410.000000,184.000000 
	C410.000000,209.991501 410.000000,235.983017 410.000000,261.980896 
	C273.719666,261.987244 137.439346,261.987244 1.079506,261.987244 
	C1.079506,175.081482 1.079506,88.162857 1.079506,1.122116 
	C137.333267,1.122116 273.666626,1.122116 410.000000,1.122088 
	C410.000000,61.435600 410.000000,121.749146 409.664734,182.421906 
	C401.145996,169.860779 393.926422,156.373703 391.114441,141.253815 
	C387.552185,122.099991 385.044983,102.751297 381.970551,83.504578 
	C381.047546,77.726280 378.788635,72.643188 372.965607,69.683838 
	C372.640106,69.398346 372.395111,69.377357 372.150116,69.356369 
	C372.214630,69.741692 372.279144,70.127014 372.432068,70.912910 
	C374.781921,86.451744 377.155426,101.574310 379.278076,116.732010 
	C382.328735,138.516434 388.225433,159.328873 399.921570,178.465561 
	C387.201080,187.811340 374.631073,197.046570 361.919495,206.385788 
	C363.699890,207.333984 365.108307,208.105057 366.535278,208.840012 
	C370.520569,210.892593 373.774414,211.238647 377.912567,207.884918 
	C388.262695,199.496719 399.261475,191.908936 410.000000,184.000000 
M39.224869,191.637955 
	C39.005947,191.533798 38.787025,191.429642 38.568104,191.325470 
	C38.719135,191.545990 38.870167,191.766525 39.215675,192.677765 
	C40.639477,193.447083 41.992424,194.415634 43.499252,194.951492 
	C50.304691,197.371628 57.009876,200.946930 64.017967,201.735687 
	C77.169205,203.215820 90.352516,202.218689 102.161865,194.983734 
	C98.630524,189.238327 94.760742,187.978958 87.883774,191.073135 
	C75.748680,196.533127 63.218838,197.398758 50.337074,194.850235 
	C46.494415,194.089996 42.717434,192.997772 39.224869,191.637955 
M278.847473,23.383738 
	C278.561462,23.371994 278.275421,23.360252 277.989441,23.348505 
	C278.106812,23.741648 278.224213,24.134792 278.662964,25.146381 
	C282.963623,31.537296 286.237610,38.335182 287.344238,46.379883 
	C265.283905,52.288136 250.155472,65.852440 241.205841,86.549721 
	C250.304855,87.750938 252.654053,86.149773 258.070068,79.695541 
	C263.422363,73.317230 269.363586,67.163277 276.026062,62.235237 
	C281.900024,57.890408 289.125671,55.373001 295.874512,51.988441 
	C293.028076,41.237461 288.487244,31.137941 278.847473,23.383738 
M120.854301,66.387405 
	C120.573036,66.373718 120.291779,66.360031 120.010513,66.346352 
	C120.120354,66.737503 120.230194,67.128662 120.647942,68.212662 
	C121.315933,70.261314 122.274887,72.264427 122.603844,74.366142 
	C124.480553,86.356422 126.463409,98.338989 127.930115,110.382637 
	C130.767166,133.678574 136.140228,156.136627 148.245468,176.565323 
	C148.637573,177.227051 148.681107,178.095337 148.216797,179.042816 
	C135.798523,188.091476 123.380257,197.140121 110.971077,206.182159 
	C112.927719,207.660446 114.411652,208.939499 116.047333,209.980042 
	C118.447449,211.506882 120.272362,214.186661 123.929024,211.124939 
	C134.172989,202.547699 144.837753,194.474136 155.266357,186.114365 
	C156.158432,185.399246 156.529892,184.034683 157.012558,182.237228 
	C153.001770,173.216156 148.740189,164.296432 145.043854,155.148300 
	C140.455460,143.792328 138.185425,131.874878 136.883148,119.675468 
	C135.761459,109.168091 133.181549,98.811890 132.156265,88.300095 
	C131.292511,79.444359 128.158325,72.295876 120.854301,66.387405 
M165.830322,176.261322 
	C165.887573,185.043137 165.872375,193.826767 166.093353,202.604446 
	C166.121109,203.707245 167.393555,204.778717 168.996765,206.020477 
	C181.222763,205.993362 193.449127,206.004440 205.674332,205.891129 
	C207.214417,205.876846 208.748459,205.208344 210.544815,204.167175 
	C210.317841,188.747955 210.090866,173.328751 210.019455,157.002243 
	C210.019455,148.076477 210.019455,139.150726 210.019455,130.406982 
	C215.561874,129.941254 220.466385,129.529144 226.162094,129.164337 
	C226.944122,128.342026 228.283676,127.601776 228.418747,126.684227 
	C229.380447,120.150551 230.096191,113.580658 231.198456,106.288155 
	C232.008331,102.621262 232.818192,98.954369 233.726166,94.843246 
	C225.218735,94.843246 217.293533,94.843246 208.835297,94.843246 
	C208.835297,84.047127 208.622177,73.584641 208.933517,63.137798 
	C209.070328,58.547665 209.672852,53.787819 211.120712,49.458450 
	C214.163437,40.360203 221.715195,36.608292 230.928436,39.069687 
	C237.098434,40.718056 240.900772,45.169426 242.745270,50.955006 
	C244.377991,56.076355 245.127548,61.479263 246.388367,67.320068 
	C255.663712,55.431850 267.551361,47.576454 281.392639,42.829308 
	C278.318481,30.630451 270.317688,20.587044 258.622650,14.636421 
	C240.280624,5.303685 220.915039,4.420166 201.258102,8.962511 
	C186.152115,12.453214 175.060516,21.674644 169.843613,36.269981 
	C166.761398,44.893074 165.182999,54.268532 164.375977,63.433128 
	C163.461212,73.821182 164.164871,84.351761 164.164871,95.715042 
	C158.036194,95.715042 151.494049,95.715042 144.378876,95.715042 
	C144.996597,101.096741 145.505539,105.530884 145.883072,110.842628 
	C146.773102,117.205444 147.663147,123.568260 148.581467,130.133316 
	C153.507614,130.133316 157.830841,130.396576 162.101929,130.038422 
	C164.833130,129.809372 165.188583,131.016357 164.980621,133.995865 
	C164.971359,139.937531 164.840485,145.882111 164.982849,151.820145 
	C165.171173,159.673462 165.573441,167.521637 165.830322,176.261322 
M380.160583,146.874481 
	C380.160583,146.874481 380.263367,146.828140 380.233337,146.030457 
	C379.832031,144.420578 379.315399,142.829666 379.046844,141.197922 
	C375.677948,120.729401 372.010010,100.301544 369.153595,79.761078 
	C367.856934,70.436974 363.217682,64.585823 354.961090,61.039257 
	C352.985809,60.190784 350.944672,59.449306 348.873199,58.878754 
	C336.996613,55.607475 324.975464,55.383049 312.805695,56.966724 
	C283.391724,60.794422 261.466370,81.959152 257.869751,110.741661 
	C255.492386,124.715225 255.541229,138.587051 260.182404,152.133453 
	C271.970245,186.539185 308.005249,199.070892 335.696259,186.704681 
	C344.203796,182.905396 352.785278,185.038803 357.368042,192.330399 
	C358.910614,194.784836 360.817749,197.010147 362.680817,199.504227 
	C373.195221,191.822113 383.219269,184.498260 392.662598,177.598709 
	C388.527832,167.295120 384.656219,157.647339 380.830200,147.481583 
	C380.611633,147.241928 380.393097,147.002258 380.160583,146.874481 
M12.243115,158.588089 
	C19.067822,173.008850 29.715845,183.199966 45.015331,188.273727 
	C58.815983,192.850433 72.363945,192.450623 85.777229,186.622467 
	C95.072357,182.583649 101.636818,184.666443 107.295097,192.990570 
	C108.756767,195.140884 110.344841,197.205246 112.134430,199.666031 
	C122.499252,192.038925 132.602554,184.604248 141.867386,177.786575 
	C137.456696,165.936493 132.760178,154.958496 129.329315,143.598267 
	C126.420372,133.966232 124.973976,123.892494 123.114754,113.157112 
	C121.579536,102.122772 119.609718,91.126877 118.629578,80.043449 
	C117.620308,68.630814 110.775093,62.994564 100.886475,59.747849 
	C88.130264,55.559624 75.069771,55.025879 61.910290,57.099571 
	C42.041622,60.230507 25.636755,69.025665 15.339994,87.012131 
	C8.828792,98.385956 6.448005,110.932274 6.012279,123.893822 
	C5.616892,135.655426 6.751405,147.160110 12.243115,158.588089 
M223.003189,161.392426 
	C223.003189,154.307831 223.003189,147.223236 223.003189,139.866486 
	C229.672180,139.866486 235.621185,139.866486 240.542694,139.866486 
	C243.071609,126.976334 245.547089,114.576286 247.869705,102.147675 
	C248.077698,101.034706 247.683960,99.279907 246.898712,98.612000 
	C244.721054,96.759758 242.188873,95.324341 238.770798,93.037910 
	C236.462021,107.138718 234.299973,120.343399 232.037811,134.159546 
	C228.503586,134.159546 224.530594,134.419250 220.606567,134.091949 
	C215.992172,133.707092 214.020889,134.968063 214.348648,140.076035 
	C214.913025,148.870880 214.885513,157.709305 214.934402,166.530396 
	C215.014252,180.942245 214.955994,195.354843 214.955994,210.644562 
	C200.914322,210.644562 187.032013,210.644562 172.871887,210.644562 
	C173.584961,213.355118 174.059616,215.159409 174.478867,216.753052 
	C191.093292,216.753052 207.358337,216.753052 223.003189,216.753052 
	C223.003189,198.452621 223.003189,180.407364 223.003189,161.392426 
M222.000549,71.660675 
	C222.826523,61.697948 223.400833,51.590420 236.005936,48.650169 
	C228.221313,40.107121 218.436340,42.139362 215.169479,52.395679 
	C214.667068,53.973000 214.121918,55.629902 214.107742,57.254456 
	C214.013702,68.030823 214.059052,78.808403 214.059052,89.751816 
	C216.841324,89.751816 219.234726,89.751816 222.000549,89.751816 
	C222.000549,83.881691 222.000549,78.252739 222.000549,71.660675 
M347.330872,190.013260 
	C344.113129,190.427460 340.608643,190.159683 337.726288,191.371567 
	C323.752289,197.246902 309.671844,197.674896 295.304443,193.258484 
	C293.696411,192.764191 292.057281,192.371063 290.432587,191.931030 
	C310.636810,205.214325 331.602325,204.857391 353.744965,195.134781 
	C351.688812,193.369781 349.870544,191.808975 347.330872,190.013260 
M128.505768,247.998367 
	C127.957809,250.579849 127.409859,253.161331 126.861900,255.742813 
	C127.508911,256.007751 128.155914,256.272675 128.802933,256.537628 
	C130.094299,254.194992 131.385681,251.852371 132.826385,249.238831 
	C132.963989,251.605087 133.085236,253.690079 133.200104,255.665253 
	C138.238220,257.405182 137.234924,251.809372 140.192307,250.378693 
	C139.812119,252.410629 139.504242,254.056152 139.190933,255.730606 
	C143.698059,255.644318 145.812943,249.491486 144.476578,241.303696 
	C138.525177,239.134125 138.825272,245.128784 136.204971,247.810959 
	C135.818527,244.109955 136.352844,239.759521 130.209930,241.324219 
	C129.806870,243.208267 129.372345,245.239502 128.505768,247.998367 
M216.610641,255.241928 
	C213.269531,252.092392 219.381256,250.552292 217.534256,247.862015 
	C216.547272,246.764297 215.925797,245.360718 214.953903,244.269150 
	C213.854675,243.034546 212.533844,241.300064 211.148254,241.134918 
	C204.085968,240.293259 207.035141,247.662933 204.072617,250.399216 
	C203.388718,251.030884 203.726547,253.439407 204.366516,254.606934 
	C204.821930,255.437759 206.554245,255.865356 207.748154,255.935242 
	C210.565689,256.100159 213.402023,255.943924 216.610641,255.241928 
M281.650177,241.835663 
	C277.975006,244.336334 276.393768,247.741928 277.600861,252.090302 
	C278.839050,256.550568 282.531799,256.290283 286.011383,255.962051 
	C289.974243,255.588242 292.191040,251.458755 292.039124,245.785583 
	C291.919312,241.311752 289.170471,240.784775 285.697479,241.004974 
	C284.548157,241.077835 283.400513,241.176514 281.650177,241.835663 
M166.017609,242.521042 
	C165.362473,245.675385 164.596634,248.811920 164.085342,251.989410 
	C163.633545,254.797211 163.946121,257.128265 167.564880,255.628586 
	C168.067749,253.617371 168.512497,251.838638 168.957230,250.059906 
	C169.336685,250.063431 169.716141,250.066971 170.095596,250.070511 
	C170.584900,251.998718 171.074203,253.926926 171.736938,256.538574 
	C177.732895,254.309830 172.776550,247.401764 176.959396,244.958450 
	C176.091522,243.686905 175.247971,242.397247 174.329361,241.163452 
	C174.192520,240.979675 173.698029,241.000488 173.376694,241.025558 
	C170.956406,241.214584 168.538071,241.428391 166.017609,242.521042 
M111.003578,252.341293 
	C110.858841,253.421295 110.714096,254.501297 110.552811,255.704758 
	C115.549828,257.235260 114.853088,253.198532 115.171349,250.625702 
	C119.162727,249.899277 116.280304,258.269928 121.916542,255.433441 
	C121.750656,253.181580 120.906395,250.871994 121.570885,249.141861 
	C124.308174,242.014786 123.706581,240.923874 116.061455,241.002258 
	C115.245827,241.010620 114.430038,241.003357 113.475845,241.003357 
	C112.624199,244.631592 111.818588,248.063736 111.003578,252.341293 
M237.368088,252.109100 
	C238.393280,255.779968 238.393280,255.779968 242.213135,255.398590 
	C241.730606,250.731812 241.248245,246.066696 240.745789,241.207306 
	C238.832565,241.207306 237.069687,241.207306 235.346405,241.207306 
	C233.200104,246.014603 231.101013,250.716125 229.001923,255.417648 
	C229.283066,255.781281 229.564209,256.144928 229.845367,256.508575 
	C231.229736,255.494949 232.594025,254.452194 234.006287,253.479080 
	C234.801987,252.930847 235.676773,252.497391 237.368088,252.109100 
M92.866592,255.943909 
	C94.241013,255.159012 95.625771,254.391525 96.985977,253.582733 
	C97.810593,253.092422 98.561432,252.236847 99.415535,252.135330 
	C99.866943,252.081711 100.408730,253.356033 101.015862,253.915649 
	C101.986481,254.810318 103.045464,255.609116 104.067093,256.448425 
	C104.452126,256.085724 104.837158,255.723022 105.222198,255.360321 
	C104.730659,250.691010 104.239120,246.021683 103.729904,241.184433 
	C101.788101,241.184433 100.018013,241.184433 98.234413,241.184433 
	C96.175743,246.061768 94.170395,250.812775 92.866592,255.943909 
M28.005224,246.454788 
	C26.665337,249.476501 25.325447,252.498215 23.985558,255.519928 
	C24.316122,255.878006 24.646687,256.236084 24.977251,256.594147 
	C26.313171,255.612946 27.644241,254.625061 28.986885,253.653152 
	C29.760921,253.092865 30.510180,252.255096 31.355396,252.133972 
	C31.811602,252.068558 32.406555,253.291000 33.009575,253.857178 
	C33.974808,254.763382 34.998806,255.606995 35.997959,256.477081 
	C36.402004,256.122223 36.806046,255.767365 37.210091,255.412506 
	C36.730190,250.744080 36.250286,246.075668 35.750114,241.210022 
	C33.838882,241.210022 32.074085,241.210022 30.457067,241.210022 
	C29.610922,242.788284 28.841671,244.223114 28.005224,246.454788 
M328.252747,241.329865 
	C326.168701,246.043259 324.084656,250.756638 322.000610,255.470032 
	C322.296967,255.826065 322.593353,256.182098 322.889709,256.538116 
	C324.260620,255.497070 325.623169,254.444717 327.006042,253.419800 
	C327.675629,252.923523 328.399902,252.091537 329.069122,252.120117 
	C329.729492,252.148331 330.363525,253.057281 330.987885,253.604080 
	C332.016663,254.505096 333.028351,255.425629 334.047241,256.337921 
	C334.447723,255.968567 334.848175,255.599213 335.248627,255.229843 
	C334.740295,250.559052 334.231964,245.888245 333.704529,241.041840 
	C331.993958,241.041840 330.520416,241.041840 328.252747,241.329865 
M356.003906,243.400146 
	C355.266846,247.451645 354.529755,251.503159 353.753815,255.768188 
	C357.593323,255.768188 360.679108,255.768188 363.764862,255.768188 
	C363.926910,255.244476 364.088959,254.720779 364.251007,254.197067 
	C362.192047,253.544083 360.133118,252.891098 358.074158,252.238113 
	C358.008087,251.901566 357.942017,251.565033 357.875946,251.228500 
	C359.850067,250.626205 361.824188,250.023926 363.798309,249.421646 
	C363.958954,249.020187 364.119568,248.618729 364.280212,248.217270 
	C362.660828,247.617188 361.041473,247.017105 358.385254,246.032791 
	C361.463837,244.552246 363.806854,243.425446 366.149872,242.298645 
	C365.958038,241.683929 365.766235,241.069229 365.574402,240.454514 
	C362.389160,241.137558 359.203949,241.820587 356.003906,243.400146 
M157.528107,247.016220 
	C156.544464,247.016220 155.560822,247.016220 154.577194,247.016220 
	C154.428131,246.641495 154.279068,246.266785 154.130005,245.892059 
	C156.473907,244.694000 158.817810,243.495941 161.161713,242.297882 
	C161.032013,241.960693 160.902328,241.623505 160.772629,241.286316 
	C157.586853,241.286316 154.401077,241.286316 151.283554,241.286316 
	C150.432358,246.241486 149.627533,250.926697 148.800690,255.740082 
	C152.681000,255.740082 155.770248,255.740082 158.966431,255.740082 
	C158.917221,250.827026 154.569138,254.394333 152.702026,251.690140 
	C154.081894,251.092255 155.312317,250.203476 156.618896,250.072159 
	C158.859299,249.847000 159.795746,249.284485 157.528107,247.016220 
M254.760773,246.962738 
	C254.145599,246.208496 253.530426,245.454254 252.808182,244.568726 
	C254.719376,243.330353 259.270844,246.378937 257.178253,240.173553 
	C254.371628,241.208740 250.827713,241.451019 249.149170,243.345795 
	C245.032944,247.992264 252.913803,248.923233 252.899826,253.114197 
	C250.897934,252.806931 249.095657,252.530304 246.623322,252.150833 
	C247.149475,253.715118 247.583176,255.791519 247.882843,255.772339 
	C250.551575,255.601608 253.959457,255.879562 255.594742,254.356247 
	C256.791260,253.241669 255.648712,249.616089 254.760773,246.962738 
M375.668915,244.078110 
	C377.476349,243.852997 380.846527,245.711975 379.353638,240.173370 
	C376.433960,241.205307 372.845917,241.393509 371.167023,243.280411 
	C367.034760,247.924591 374.792999,248.920242 374.954193,253.094604 
	C372.912903,252.804367 371.113800,252.548584 369.212860,252.278305 
	C369.212860,253.481201 369.212860,254.567093 369.212860,255.994171 
	C371.207123,255.994171 373.116486,256.372284 374.769684,255.883621 
	C376.302887,255.430435 378.480499,254.239639 378.727509,253.035767 
	C379.098907,251.225433 378.228180,249.045471 377.502716,247.170807 
	C377.109009,246.153381 375.923492,245.442368 375.668915,244.078110 
M271.996643,250.622589 
	C270.723083,248.609528 269.449524,246.596466 268.412018,244.956543 
	C269.969269,244.240036 271.663483,243.460495 273.357697,242.680969 
	C273.065796,241.913437 272.773895,241.145905 272.481964,240.378372 
	C269.674042,241.191345 265.949768,241.149948 264.238495,242.985382 
	C259.951935,247.582977 267.645386,248.958740 267.999542,253.152161 
	C265.828644,252.813721 264.038391,252.534622 262.248138,252.255524 
	C261.977356,252.668839 261.706604,253.082169 261.435822,253.495499 
	C262.447174,254.328613 263.410889,255.796906 264.478760,255.877060 
	C267.832886,256.128815 271.835480,256.869812 271.996643,250.622589 
M184.419510,255.997223 
	C186.264893,255.289444 188.836777,255.116684 189.823654,253.776642 
	C193.201660,249.189758 187.263123,247.648773 185.646011,243.962112 
	C187.754410,244.290710 189.222015,244.519440 190.752808,244.758026 
	C191.004990,243.417603 191.220184,242.273743 191.745071,239.483871 
	C187.809830,241.162842 183.485504,241.713882 182.612549,243.746918 
	C181.752533,245.749802 184.389389,249.254181 185.491974,252.099777 
	C186.171661,255.714096 179.133545,248.785767 180.216919,255.756424 
	C181.305450,255.834915 182.430984,255.916077 184.419510,255.997223 
M298.352905,244.113037 
	C298.178711,247.508682 296.677246,251.839020 298.217987,254.027039 
	C299.648895,256.058990 304.325928,255.805115 307.573700,256.557678 
	C307.822083,255.803772 308.070435,255.049881 308.318787,254.295975 
	C307.144043,253.853851 305.995392,253.135834 304.789581,253.021805 
	C301.299866,252.691788 300.191589,250.699829 301.191071,247.699860 
	C302.413055,244.031845 305.273834,243.257660 308.746399,244.567200 
	C309.022827,243.286057 309.251801,242.224823 309.810760,239.634186 
	C305.640228,241.142227 302.302521,242.349136 298.352905,244.113037 
M84.096657,250.647354 
	C86.130028,249.959976 88.163399,249.272598 91.369125,248.188919 
	C88.095482,247.022583 86.388809,246.414536 84.682129,245.806473 
	C84.868660,245.402328 85.055191,244.998169 85.241722,244.594025 
	C87.242668,244.038025 89.243607,243.482040 91.244553,242.926056 
	C91.095375,242.376282 90.946198,241.826508 90.797028,241.276733 
	C88.030983,241.276733 83.321877,240.381866 82.837860,241.432831 
	C80.825325,245.802765 80.086357,250.759201 78.859863,255.579895 
	C83.237518,256.781982 83.741898,254.261063 84.096657,250.647354 
M342.992676,245.642654 
	C342.425934,248.946014 341.859192,252.249359 341.292480,255.552719 
	C341.555847,255.896149 341.819244,256.239594 342.082642,256.583038 
	C343.384949,255.467941 345.384857,254.581650 345.841614,253.189178 
	C346.740051,250.450043 346.781219,247.429718 347.132416,244.853165 
	C348.961151,243.899948 350.567963,243.062408 352.174774,242.224854 
	C352.020355,241.936005 351.865906,241.647171 351.711456,241.358322 
	C347.847748,241.358322 343.984039,241.358322 340.120300,241.358322 
	C339.965759,241.712448 339.811218,242.066574 339.656677,242.420685 
	C340.744415,243.206741 341.832153,243.992783 342.992676,245.642654 
M57.994118,249.391205 
	C57.619564,251.484634 57.245007,253.578079 56.767990,256.244171 
	C60.782402,255.310196 64.588547,257.544525 67.821014,253.248962 
	C64.987968,253.072433 63.222309,252.962402 61.427841,252.850586 
	C63.894638,241.276642 63.882938,241.213806 59.364712,241.430862 
	C58.911366,243.787430 58.456993,246.149338 57.994118,249.391205 
M48.638977,252.992554 
	C48.131611,251.879791 47.088467,250.698013 47.221184,249.667709 
	C47.566387,246.987808 48.427876,244.374420 49.118103,241.594635 
	C45.782433,240.089584 44.494488,241.701004 44.032959,244.536591 
	C43.427353,248.257385 42.920250,251.994232 42.366917,255.750397 
	C45.646374,255.750397 48.734390,255.750397 51.822407,255.750397 
	C51.980499,255.403824 52.138588,255.057236 52.296677,254.710663 
	C51.367718,254.141403 50.438759,253.572144 48.638977,252.992554 
M160.897873,137.413910 
	C160.043518,136.551697 159.231003,134.999329 158.327728,134.944611 
	C154.008835,134.682922 149.665344,134.827255 145.330597,134.827255 
	C148.776154,137.535065 152.242538,139.569626 155.973892,140.801941 
	C157.627563,141.348114 160.973679,142.446625 160.897873,137.413910 
M315.437012,255.983429 
	C316.864777,251.176620 318.292572,246.369812 319.720337,241.563019 
	C318.964630,241.190598 318.208923,240.818176 317.453247,240.445770 
	C316.636383,241.477524 315.457825,242.391739 315.076508,243.565033 
	C314.231995,246.163681 313.532959,248.869110 313.284973,251.577713 
	C313.155212,252.995331 314.104187,254.511688 315.437012,255.983429 
z"
          />
          <path
            fill="#BF2130"
            opacity="1.000000"
            stroke="none"
            d="
M410.000000,183.750000 
	C399.261475,191.908936 388.262695,199.496719 377.912567,207.884918 
	C373.774414,211.238647 370.520569,210.892593 366.535278,208.840012 
	C365.108307,208.105057 363.699890,207.333984 361.919495,206.385788 
	C374.631073,197.046570 387.201080,187.811340 399.921570,178.465561 
	C388.225433,159.328873 382.328735,138.516434 379.278076,116.732010 
	C377.155426,101.574310 374.781921,86.451744 372.617157,70.783737 
	C372.713867,70.253983 373.046112,69.948349 373.046112,69.948349 
	C378.788635,72.643188 381.047546,77.726280 381.970551,83.504578 
	C385.044983,102.751297 387.552185,122.099991 391.114441,141.253815 
	C393.926422,156.373703 401.145996,169.860779 409.664734,182.890564 
	C410.000000,183.000000 410.000000,183.500000 410.000000,183.750000 
z"
          />
          <path
            fill="#ED1D25"
            opacity="1.000000"
            stroke="none"
            d="
M146.014496,109.965027 
	C145.505539,105.530884 144.996597,101.096741 144.378876,95.715042 
	C151.494049,95.715042 158.036194,95.715042 164.164871,95.715042 
	C164.164871,84.351761 163.461212,73.821182 164.375977,63.433128 
	C165.182999,54.268532 166.761398,44.893074 169.843613,36.269981 
	C175.060516,21.674644 186.152115,12.453214 201.258102,8.962511 
	C220.915039,4.420166 240.280624,5.303685 258.622650,14.636421 
	C270.317688,20.587044 278.318481,30.630451 281.392639,42.829308 
	C267.551361,47.576454 255.663712,55.431850 246.388367,67.320068 
	C245.127548,61.479263 244.377991,56.076355 242.745270,50.955006 
	C240.900772,45.169426 237.098434,40.718056 230.928436,39.069687 
	C221.715195,36.608292 214.163437,40.360203 211.120712,49.458450 
	C209.672852,53.787819 209.070328,58.547665 208.933517,63.137798 
	C208.622177,73.584641 208.835297,84.047127 208.835297,94.843246 
	C217.293533,94.843246 225.218735,94.843246 233.726166,94.843246 
	C232.818192,98.954369 232.008331,102.621262 230.582962,106.652084 
	C201.939545,106.758072 173.914337,106.577339 146.014496,109.965027 
z"
          />
          <path
            fill="#ED1D25"
            opacity="1.000000"
            stroke="none"
            d="
M12.178265,157.875137 
	C6.751405,147.160110 5.616892,135.655426 6.012279,123.893822 
	C6.448005,110.932274 8.828792,98.385956 15.339994,87.012131 
	C25.636755,69.025665 42.041622,60.230507 61.910290,57.099571 
	C75.069771,55.025879 88.130264,55.559624 100.886475,59.747849 
	C110.775093,62.994564 117.620308,68.630814 118.629578,80.043449 
	C119.609718,91.126877 121.579536,102.122772 122.598785,113.593750 
	C111.519653,116.350403 100.956497,118.670425 90.267868,120.548843 
	C89.921776,116.951477 89.762871,113.789963 89.468330,110.641136 
	C88.745361,102.912071 84.928314,98.581894 77.359779,96.791145 
	C63.581738,93.531197 49.743980,101.133118 45.134525,114.615715 
	C42.422283,122.548996 42.302605,130.617096 44.070381,138.890854 
	C33.212036,145.330444 22.695152,151.602798 12.178265,157.875137 
z"
          />
          <path
            fill="#FE3701"
            opacity="1.000000"
            stroke="none"
            d="
M380.784607,147.999573 
	C384.656219,157.647339 388.527832,167.295120 392.662598,177.598709 
	C383.219269,184.498260 373.195221,191.822113 362.680817,199.504227 
	C360.817749,197.010147 358.910614,194.784836 357.368042,192.330399 
	C352.785278,185.038803 344.203796,182.905396 335.696259,186.704681 
	C308.005249,199.070892 271.970245,186.539185 260.182404,152.133453 
	C255.541229,138.587051 255.492386,124.715225 258.429047,110.365128 
	C270.966797,111.992714 282.945251,113.996826 294.937195,116.422539 
	C291.860016,130.261002 292.970184,143.428925 303.636902,152.684143 
	C316.448669,163.800537 331.774902,161.013641 346.476166,156.718307 
	C344.989929,147.600357 343.554718,138.795273 342.477417,130.041290 
	C352.883606,134.726089 362.919708,139.386505 372.989166,143.973724 
	C375.356140,145.052017 377.835632,145.883255 380.263367,146.828140 
	C380.263367,146.828140 380.160583,146.874481 380.163086,147.067017 
	C380.263123,147.597260 380.469482,147.843918 380.784607,147.999573 
z"
          />
          <path
            fill="#ED1D25"
            opacity="1.000000"
            stroke="none"
            d="
M380.248352,146.429291 
	C377.835632,145.883255 375.356140,145.052017 372.989166,143.973724 
	C362.919708,139.386505 352.883606,134.726089 342.347839,129.639160 
	C341.245300,123.906456 340.368927,118.641830 340.068665,113.344543 
	C339.409088,101.708809 334.354034,96.507095 322.759644,96.022224 
	C310.138611,95.494408 301.169006,101.932167 294.923706,116.000946 
	C282.945251,113.996826 270.966797,111.992714 258.560760,109.970276 
	C261.466370,81.959152 283.391724,60.794422 312.805695,56.966724 
	C324.975464,55.383049 336.996613,55.607475 348.873199,58.878754 
	C350.944672,59.449306 352.985809,60.190784 354.961090,61.039257 
	C363.217682,64.585823 367.856934,70.436974 369.153595,79.761078 
	C372.010010,100.301544 375.677948,120.729401 379.046844,141.197922 
	C379.315399,142.829666 379.832031,144.420578 380.248352,146.429291 
z"
          />
          <path
            fill="#FE3701"
            opacity="1.000000"
            stroke="none"
            d="
M12.210691,158.231613 
	C22.695152,151.602798 33.212036,145.330444 44.282440,139.171600 
	C51.860397,161.065414 73.874580,164.253387 95.692467,156.460068 
	C93.919815,144.594864 92.156578,132.792664 90.393333,120.990456 
	C100.956497,118.670425 111.519653,116.350403 122.481613,114.022293 
	C124.973976,123.892494 126.420372,133.966232 129.329315,143.598267 
	C132.760178,154.958496 137.456696,165.936493 141.867386,177.786575 
	C132.602554,184.604248 122.499252,192.038925 112.134430,199.666031 
	C110.344841,197.205246 108.756767,195.140884 107.295097,192.990570 
	C101.636818,184.666443 95.072357,182.583649 85.777229,186.622467 
	C72.363945,192.450623 58.815983,192.850433 45.015331,188.273727 
	C29.715845,183.199966 19.067822,173.008850 12.210691,158.231613 
z"
          />
          <path
            fill="#FE3501"
            opacity="1.000000"
            stroke="none"
            d="
M145.948776,110.403824 
	C173.914337,106.577339 201.939545,106.758072 230.428833,107.019157 
	C230.096191,113.580658 229.380447,120.150551 228.418747,126.684227 
	C228.283676,127.601776 226.944122,128.342026 225.270554,129.064529 
	C220.405914,128.949554 216.419601,128.748764 212.467575,129.029846 
	C211.247513,129.116623 209.102310,130.523041 209.107422,131.314087 
	C209.164688,140.180481 209.559708,149.044724 209.863876,157.909531 
	C210.090866,173.328751 210.317841,188.747955 209.914459,204.528244 
	C203.628448,204.898087 197.969849,204.794739 192.317810,204.942429 
	C184.239120,205.153549 176.165237,205.548355 168.089294,205.864700 
	C167.393555,204.778717 166.121109,203.707245 166.093353,202.604446 
	C165.872375,193.826767 165.887573,185.043137 165.933304,175.319443 
	C166.052475,168.446686 166.195984,162.512741 166.053406,156.585693 
	C165.864929,148.750351 165.452393,140.920395 165.136154,133.088135 
	C165.188583,131.016357 164.833130,129.809372 162.101929,130.038422 
	C157.830841,130.396576 153.507614,130.133316 148.581467,130.133316 
	C147.663147,123.568260 146.773102,117.205444 145.948776,110.403824 
z"
          />
          <path
            fill="#BF2130"
            opacity="1.000000"
            stroke="none"
            d="
M223.003189,161.877258 
	C223.003189,180.407364 223.003189,198.452621 223.003189,216.753052 
	C207.358337,216.753052 191.093292,216.753052 174.478867,216.753052 
	C174.059616,215.159409 173.584961,213.355118 172.871887,210.644562 
	C187.032013,210.644562 200.914322,210.644562 214.955994,210.644562 
	C214.955994,195.354843 215.014252,180.942245 214.934402,166.530396 
	C214.885513,157.709305 214.913025,148.870880 214.348648,140.076035 
	C214.020889,134.968063 215.992172,133.707092 220.606567,134.091949 
	C224.530594,134.419250 228.503586,134.159546 232.037811,134.159546 
	C234.299973,120.343399 236.462021,107.138718 238.770798,93.037910 
	C242.188873,95.324341 244.721054,96.759758 246.898712,98.612000 
	C247.683960,99.279907 248.077698,101.034706 247.869705,102.147675 
	C245.547089,114.576286 243.071609,126.976334 240.542694,139.866486 
	C235.621185,139.866486 229.672180,139.866486 223.003189,139.866486 
	C223.003189,147.223236 223.003189,154.307831 223.003189,161.877258 
z"
          />
          <path
            fill="#BF2230"
            opacity="1.000000"
            stroke="none"
            d="
M121.049026,66.945236 
	C128.158325,72.295876 131.292511,79.444359 132.156265,88.300095 
	C133.181549,98.811890 135.761459,109.168091 136.883148,119.675468 
	C138.185425,131.874878 140.455460,143.792328 145.043854,155.148300 
	C148.740189,164.296432 153.001770,173.216156 156.744644,182.489136 
	C153.946854,181.449814 151.416992,180.158585 148.887115,178.867340 
	C148.681107,178.095337 148.637573,177.227051 148.245468,176.565323 
	C136.140228,156.136627 130.767166,133.678574 127.930115,110.382637 
	C126.463409,98.338989 124.480553,86.356422 122.603844,74.366142 
	C122.274887,72.264427 121.315933,70.261314 120.680176,67.733627 
	C120.712410,67.254585 121.049026,66.945236 121.049026,66.945236 
z"
          />
          <path
            fill="#BF202F"
            opacity="1.000000"
            stroke="none"
            d="
M279.044952,23.949551 
	C288.487244,31.137941 293.028076,41.237461 295.874512,51.988441 
	C289.125671,55.373001 281.900024,57.890408 276.026062,62.235237 
	C269.363586,67.163277 263.422363,73.317230 258.070068,79.695541 
	C252.654053,86.149773 250.304855,87.750938 241.205841,86.549721 
	C250.155472,65.852440 265.283905,52.288136 287.344238,46.379883 
	C286.237610,38.335182 282.963623,31.537296 278.686981,24.703346 
	C278.710999,24.260313 279.044952,23.949551 279.044952,23.949551 
z"
          />
          <path
            fill="#EB1E26"
            opacity="1.000000"
            stroke="none"
            d="
M148.551956,178.955078 
	C151.416992,180.158585 153.946854,181.449814 156.809967,182.856445 
	C156.529892,184.034683 156.158432,185.399246 155.266357,186.114365 
	C144.837753,194.474136 134.172989,202.547699 123.929024,211.124939 
	C120.272362,214.186661 118.447449,211.506882 116.047333,209.980042 
	C114.411652,208.939499 112.927719,207.660446 110.971077,206.182159 
	C123.380257,197.140121 135.798523,188.091476 148.551956,178.955078 
z"
          />
          <path
            fill="#BE1F2E"
            opacity="1.000000"
            stroke="none"
            d="
M222.000549,72.142227 
	C222.000549,78.252739 222.000549,83.881691 222.000549,89.751816 
	C219.234726,89.751816 216.841324,89.751816 214.059052,89.751816 
	C214.059052,78.808403 214.013702,68.030823 214.107742,57.254456 
	C214.121918,55.629902 214.667068,53.973000 215.169479,52.395679 
	C218.436340,42.139362 228.221313,40.107121 236.005936,48.650169 
	C223.400833,51.590420 222.826523,61.697948 222.000549,72.142227 
z"
          />
          <path
            fill="#C02433"
            opacity="1.000000"
            stroke="none"
            d="
M38.910362,192.057678 
	C42.717434,192.997772 46.494415,194.089996 50.337074,194.850235 
	C63.218838,197.398758 75.748680,196.533127 87.883774,191.073135 
	C94.760742,187.978958 98.630524,189.238327 102.161865,194.983734 
	C90.352516,202.218689 77.169205,203.215820 64.017967,201.735687 
	C57.009876,200.946930 50.304691,197.371628 43.499252,194.951492 
	C41.992424,194.415634 40.639477,193.447083 39.091415,192.351135 
	C38.967152,192.024506 38.910362,192.057663 38.910362,192.057678 
z"
          />
          <path
            fill="#BF2230"
            opacity="1.000000"
            stroke="none"
            d="
M347.691559,190.130707 
	C349.870544,191.808975 351.688812,193.369781 353.744965,195.134781 
	C331.602325,204.857391 310.636810,205.214325 290.432587,191.931030 
	C292.057281,192.371063 293.696411,192.764191 295.304443,193.258484 
	C309.671844,197.674896 323.752289,197.246902 337.726288,191.371567 
	C340.608643,190.159683 344.113129,190.427460 347.691559,190.130707 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M128.721786,247.634552 
	C129.372345,245.239502 129.806870,243.208267 130.209930,241.324219 
	C136.352844,239.759521 135.818527,244.109955 136.204971,247.810959 
	C138.825272,245.128784 138.525177,239.134125 144.476578,241.303696 
	C145.812943,249.491486 143.698059,255.644318 139.190933,255.730606 
	C139.504242,254.056152 139.812119,252.410629 140.192307,250.378693 
	C137.234924,251.809372 138.238220,257.405182 133.200104,255.665253 
	C133.085236,253.690079 132.963989,251.605087 132.826385,249.238831 
	C131.385681,251.852371 130.094299,254.194992 128.802933,256.537628 
	C128.155914,256.272675 127.508911,256.007751 126.861900,255.742813 
	C127.409859,253.161331 127.957809,250.579849 128.721786,247.634552 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M216.420685,255.579758 
	C213.402023,255.943924 210.565689,256.100159 207.748154,255.935242 
	C206.554245,255.865356 204.821930,255.437759 204.366516,254.606934 
	C203.726547,253.439407 203.388718,251.030884 204.072617,250.399216 
	C207.035141,247.662933 204.085968,240.293259 211.148254,241.134918 
	C212.533844,241.300064 213.854675,243.034546 214.953903,244.269150 
	C215.925797,245.360718 216.547272,246.764297 217.534256,247.862015 
	C219.381256,250.552292 213.269531,252.092392 216.420685,255.579758 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M281.951141,241.549530 
	C283.400513,241.176514 284.548157,241.077835 285.697479,241.004974 
	C289.170471,240.784775 291.919312,241.311752 292.039124,245.785583 
	C292.191040,251.458755 289.974243,255.588242 286.011383,255.962051 
	C282.531799,256.290283 278.839050,256.550568 277.600861,252.090302 
	C276.393768,247.741928 277.975006,244.336334 281.951141,241.549530 
M287.639069,245.147278 
	C286.237091,245.127396 284.106506,244.535217 283.581238,245.203964 
	C282.402618,246.704483 281.720825,248.795242 281.488525,250.724182 
	C281.413239,251.349335 283.290192,252.615097 284.419708,252.832870 
	C285.136169,252.971024 286.554840,251.890076 286.892853,251.048355 
	C287.536346,249.445908 287.631348,247.623215 287.639069,245.147278 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M166.068359,242.077744 
	C168.538071,241.428391 170.956406,241.214584 173.376694,241.025558 
	C173.698029,241.000488 174.192520,240.979675 174.329361,241.163452 
	C175.247971,242.397247 176.091522,243.686905 176.959396,244.958450 
	C172.776550,247.401764 177.732895,254.309830 171.736938,256.538574 
	C171.074203,253.926926 170.584900,251.998718 170.095596,250.070511 
	C169.716141,250.066971 169.336685,250.063431 168.957230,250.059906 
	C168.512497,251.838638 168.067749,253.617371 167.564880,255.628586 
	C163.946121,257.128265 163.633545,254.797211 164.085342,251.989410 
	C164.596634,248.811920 165.362473,245.675385 166.068359,242.077744 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M111.008278,251.918579 
	C111.818588,248.063736 112.624199,244.631592 113.475845,241.003357 
	C114.430038,241.003357 115.245827,241.010620 116.061455,241.002258 
	C123.706581,240.923874 124.308174,242.014786 121.570885,249.141861 
	C120.906395,250.871994 121.750656,253.181580 121.916542,255.433441 
	C116.280304,258.269928 119.162727,249.899277 115.171349,250.625702 
	C114.853088,253.198532 115.549828,257.235260 110.552811,255.704758 
	C110.714096,254.501297 110.858841,253.421295 111.008278,251.918579 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M236.942017,252.060669 
	C235.676773,252.497391 234.801987,252.930847 234.006287,253.479080 
	C232.594025,254.452194 231.229736,255.494949 229.845367,256.508575 
	C229.564209,256.144928 229.283066,255.781281 229.001923,255.417648 
	C231.101013,250.716125 233.200104,246.014603 235.346405,241.207306 
	C237.069687,241.207306 238.832565,241.207306 240.745789,241.207306 
	C241.248245,246.066696 241.730606,250.731812 242.213135,255.398590 
	C238.393280,255.779968 238.393280,255.779968 236.942017,252.060669 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M92.515823,255.753845 
	C94.170395,250.812775 96.175743,246.061768 98.234413,241.184433 
	C100.018013,241.184433 101.788101,241.184433 103.729904,241.184433 
	C104.239120,246.021683 104.730659,250.691010 105.222198,255.360321 
	C104.837158,255.723022 104.452126,256.085724 104.067093,256.448425 
	C103.045464,255.609116 101.986481,254.810318 101.015862,253.915649 
	C100.408730,253.356033 99.866943,252.081711 99.415535,252.135330 
	C98.561432,252.236847 97.810593,253.092422 96.985977,253.582733 
	C95.625771,254.391525 94.241013,255.159012 92.515823,255.753845 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M28.038822,246.056366 
	C28.841671,244.223114 29.610922,242.788284 30.457067,241.210022 
	C32.074085,241.210022 33.838882,241.210022 35.750114,241.210022 
	C36.250286,246.075668 36.730190,250.744080 37.210091,255.412506 
	C36.806046,255.767365 36.402004,256.122223 35.997959,256.477081 
	C34.998806,255.606995 33.974808,254.763382 33.009575,253.857178 
	C32.406555,253.291000 31.811602,252.068558 31.355396,252.133972 
	C30.510180,252.255096 29.760921,253.092865 28.986885,253.653152 
	C27.644241,254.625061 26.313171,255.612946 24.977251,256.594147 
	C24.646687,256.236084 24.316122,255.878006 23.985558,255.519928 
	C25.325447,252.498215 26.665337,249.476501 28.038822,246.056366 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M328.649811,241.185852 
	C330.520416,241.041840 331.993958,241.041840 333.704529,241.041840 
	C334.231964,245.888245 334.740295,250.559052 335.248627,255.229843 
	C334.848175,255.599213 334.447723,255.968567 334.047241,256.337921 
	C333.028351,255.425629 332.016663,254.505096 330.987885,253.604080 
	C330.363525,253.057281 329.729492,252.148331 329.069122,252.120117 
	C328.399902,252.091537 327.675629,252.923523 327.006042,253.419800 
	C325.623169,254.444717 324.260620,255.497070 322.889709,256.538116 
	C322.593353,256.182098 322.296967,255.826065 322.000610,255.470032 
	C324.084656,250.756638 326.168701,246.043259 328.649811,241.185852 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M356.011292,242.951889 
	C359.203949,241.820587 362.389160,241.137558 365.574402,240.454514 
	C365.766235,241.069229 365.958038,241.683929 366.149872,242.298645 
	C363.806854,243.425446 361.463837,244.552246 358.385254,246.032791 
	C361.041473,247.017105 362.660828,247.617188 364.280212,248.217270 
	C364.119568,248.618729 363.958954,249.020187 363.798309,249.421646 
	C361.824188,250.023926 359.850067,250.626205 357.875946,251.228500 
	C357.942017,251.565033 358.008087,251.901566 358.074158,252.238113 
	C360.133118,252.891098 362.192047,253.544083 364.251007,254.197067 
	C364.088959,254.720779 363.926910,255.244476 363.764862,255.768188 
	C360.679108,255.768188 357.593323,255.768188 353.753815,255.768188 
	C354.529755,251.503159 355.266846,247.451645 356.011292,242.951889 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M157.937149,247.060104 
	C159.795746,249.284485 158.859299,249.847000 156.618896,250.072159 
	C155.312317,250.203476 154.081894,251.092255 152.702026,251.690140 
	C154.569138,254.394333 158.917221,250.827026 158.966431,255.740082 
	C155.770248,255.740082 152.681000,255.740082 148.800690,255.740082 
	C149.627533,250.926697 150.432358,246.241486 151.283554,241.286316 
	C154.401077,241.286316 157.586853,241.286316 160.772629,241.286316 
	C160.902328,241.623505 161.032013,241.960693 161.161713,242.297882 
	C158.817810,243.495941 156.473907,244.694000 154.130005,245.892059 
	C154.279068,246.266785 154.428131,246.641495 154.577194,247.016220 
	C155.560822,247.016220 156.544464,247.016220 157.937149,247.060104 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M255.154755,247.036224 
	C255.648712,249.616089 256.791260,253.241669 255.594742,254.356247 
	C253.959457,255.879562 250.551575,255.601608 247.882843,255.772339 
	C247.583176,255.791519 247.149475,253.715118 246.623322,252.150833 
	C249.095657,252.530304 250.897934,252.806931 252.899826,253.114197 
	C252.913803,248.923233 245.032944,247.992264 249.149170,243.345795 
	C250.827713,241.451019 254.371628,241.208740 257.178253,240.173553 
	C259.270844,246.378937 254.719376,243.330353 252.808182,244.568726 
	C253.530426,245.454254 254.145599,246.208496 255.154755,247.036224 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M375.382019,244.335632 
	C375.923492,245.442368 377.109009,246.153381 377.502716,247.170807 
	C378.228180,249.045471 379.098907,251.225433 378.727509,253.035767 
	C378.480499,254.239639 376.302887,255.430435 374.769684,255.883621 
	C373.116486,256.372284 371.207123,255.994171 369.212860,255.994171 
	C369.212860,254.567093 369.212860,253.481201 369.212860,252.278305 
	C371.113800,252.548584 372.912903,252.804367 374.954193,253.094604 
	C374.792999,248.920242 367.034760,247.924591 371.167023,243.280411 
	C372.845917,241.393509 376.433960,241.205307 379.353638,240.173370 
	C380.846527,245.711975 377.476349,243.852997 375.382019,244.335632 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M271.996521,251.041748 
	C271.835480,256.869812 267.832886,256.128815 264.478760,255.877060 
	C263.410889,255.796906 262.447174,254.328613 261.435822,253.495499 
	C261.706604,253.082169 261.977356,252.668839 262.248138,252.255524 
	C264.038391,252.534622 265.828644,252.813721 267.999542,253.152161 
	C267.645386,248.958740 259.951935,247.582977 264.238495,242.985382 
	C265.949768,241.149948 269.674042,241.191345 272.481964,240.378372 
	C272.773895,241.145905 273.065796,241.913437 273.357697,242.680969 
	C271.663483,243.460495 269.969269,244.240036 268.412018,244.956543 
	C269.449524,246.596466 270.723083,248.609528 271.996521,251.041748 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M183.988007,255.997223 
	C182.430984,255.916077 181.305450,255.834915 180.216919,255.756424 
	C179.133545,248.785767 186.171661,255.714096 185.491974,252.099777 
	C184.389389,249.254181 181.752533,245.749802 182.612549,243.746918 
	C183.485504,241.713882 187.809830,241.162842 191.745071,239.483871 
	C191.220184,242.273743 191.004990,243.417603 190.752808,244.758026 
	C189.222015,244.519440 187.754410,244.290710 185.646011,243.962112 
	C187.263123,247.648773 193.201660,249.189758 189.823654,253.776642 
	C188.836777,255.116684 186.264893,255.289444 183.988007,255.997223 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M298.658844,243.834534 
	C302.302521,242.349136 305.640228,241.142227 309.810760,239.634186 
	C309.251801,242.224823 309.022827,243.286057 308.746399,244.567200 
	C305.273834,243.257660 302.413055,244.031845 301.191071,247.699860 
	C300.191589,250.699829 301.299866,252.691788 304.789581,253.021805 
	C305.995392,253.135834 307.144043,253.853851 308.318787,254.295975 
	C308.070435,255.049881 307.822083,255.803772 307.573700,256.557678 
	C304.325928,255.805115 299.648895,256.058990 298.217987,254.027039 
	C296.677246,251.839020 298.178711,247.508682 298.658844,243.834534 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M84.053169,251.062668 
	C83.741898,254.261063 83.237518,256.781982 78.859863,255.579895 
	C80.086357,250.759201 80.825325,245.802765 82.837860,241.432831 
	C83.321877,240.381866 88.030983,241.276733 90.797028,241.276733 
	C90.946198,241.826508 91.095375,242.376282 91.244553,242.926056 
	C89.243607,243.482040 87.242668,244.038025 85.241722,244.594025 
	C85.055191,244.998169 84.868660,245.402328 84.682129,245.806473 
	C86.388809,246.414536 88.095482,247.022583 91.369125,248.188919 
	C88.163399,249.272598 86.130028,249.959976 84.053169,251.062668 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M342.956299,245.210739 
	C341.832153,243.992783 340.744415,243.206741 339.656677,242.420685 
	C339.811218,242.066574 339.965759,241.712448 340.120300,241.358322 
	C343.984039,241.358322 347.847748,241.358322 351.711456,241.358322 
	C351.865906,241.647171 352.020355,241.936005 352.174774,242.224854 
	C350.567963,243.062408 348.961151,243.899948 347.132416,244.853165 
	C346.781219,247.429718 346.740051,250.450043 345.841614,253.189178 
	C345.384857,254.581650 343.384949,255.467941 342.082642,256.583038 
	C341.819244,256.239594 341.555847,255.896149 341.292480,255.552719 
	C341.859192,252.249359 342.425934,248.946014 342.956299,245.210739 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M57.998367,248.951233 
	C58.456993,246.149338 58.911366,243.787430 59.364712,241.430862 
	C63.882938,241.213806 63.894638,241.276642 61.427841,252.850586 
	C63.222309,252.962402 64.987968,253.072433 67.821014,253.248962 
	C64.588547,257.544525 60.782402,255.310196 56.767990,256.244171 
	C57.245007,253.578079 57.619564,251.484634 57.998367,248.951233 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M49.074387,252.997711 
	C50.438759,253.572144 51.367718,254.141403 52.296677,254.710663 
	C52.138588,255.057236 51.980499,255.403824 51.822407,255.750397 
	C48.734390,255.750397 45.646374,255.750397 42.366917,255.750397 
	C42.920250,251.994232 43.427353,248.257385 44.032959,244.536591 
	C44.494488,241.701004 45.782433,240.089584 49.118103,241.594635 
	C48.427876,244.374420 47.566387,246.987808 47.221184,249.667709 
	C47.088467,250.698013 48.131611,251.879791 49.074387,252.997711 
z"
          />
          <path
            fill="#C02533"
            opacity="1.000000"
            stroke="none"
            d="
M160.898132,137.859955 
	C160.973679,142.446625 157.627563,141.348114 155.973892,140.801941 
	C152.242538,139.569626 148.776154,137.535065 145.330597,134.827255 
	C149.665344,134.827255 154.008835,134.682922 158.327728,134.944611 
	C159.231003,134.999329 160.043518,136.551697 160.898132,137.859955 
z"
          />
          <path
            fill="#BE1E2D"
            opacity="1.000000"
            stroke="none"
            d="
M315.001465,255.983444 
	C314.104187,254.511688 313.155212,252.995331 313.284973,251.577713 
	C313.532959,248.869110 314.231995,246.163681 315.076508,243.565033 
	C315.457825,242.391739 316.636383,241.477524 317.453247,240.445770 
	C318.208923,240.818176 318.964630,241.190598 319.720337,241.563019 
	C318.292572,246.369812 316.864777,251.176620 315.001465,255.983444 
z"
          />
          <path
            fill="#FFC817"
            opacity="1.000000"
            stroke="none"
            d="
M209.941666,157.455887 
	C209.559708,149.044724 209.164688,140.180481 209.107422,131.314087 
	C209.102310,130.523041 211.247513,129.116623 212.467575,129.029846 
	C216.419601,128.748764 220.405914,128.949554 224.874954,129.040863 
	C220.466385,129.529144 215.561874,129.941254 210.019455,130.406982 
	C210.019455,139.150726 210.019455,148.076477 209.941666,157.455887 
z"
          />
          <path
            fill="#FFC818"
            opacity="1.000000"
            stroke="none"
            d="
M168.543030,205.942596 
	C176.165237,205.548355 184.239120,205.153549 192.317810,204.942429 
	C197.969849,204.794739 203.628448,204.898087 209.784698,204.866135 
	C208.748459,205.208344 207.214417,205.876846 205.674332,205.891129 
	C193.449127,206.004440 181.222763,205.993362 168.543030,205.942596 
z"
          />
          <path
            fill="#FFC717"
            opacity="1.000000"
            stroke="none"
            d="
M165.058380,133.541992 
	C165.452393,140.920395 165.864929,148.750351 166.053406,156.585693 
	C166.195984,162.512741 166.052475,168.446686 165.960022,174.874786 
	C165.573441,167.521637 165.171173,159.673462 164.982849,151.820145 
	C164.840485,145.882111 164.971359,139.937531 165.058380,133.541992 
z"
          />
          <path
            fill="#BF202F"
            opacity="1.000000"
            stroke="none"
            d="
M278.526306,24.394123 
	C278.224213,24.134792 278.106812,23.741648 277.989441,23.348507 
	C278.275421,23.360252 278.561462,23.371994 278.946228,23.666645 
	C279.044952,23.949551 278.710999,24.260313 278.526306,24.394123 
z"
          />
          <path
            fill="#C02433"
            opacity="1.000000"
            stroke="none"
            d="
M38.994171,192.005768 
	C38.870167,191.766525 38.719135,191.545990 38.568104,191.325470 
	C38.787025,191.429642 39.005947,191.533798 39.067616,191.847809 
	C38.910362,192.057663 38.967152,192.024506 38.994171,192.005768 
z"
          />
          <path
            fill="#ED1D25"
            opacity="1.000000"
            stroke="none"
            d="
M380.807434,147.740570 
	C380.469482,147.843918 380.263123,147.597260 380.170044,147.011078 
	C380.393097,147.002258 380.611633,147.241928 380.807434,147.740570 
z"
          />
          <path
            fill="#BF2130"
            opacity="1.000000"
            stroke="none"
            d="
M372.528748,70.383163 
	C372.279144,70.127014 372.214630,69.741692 372.150116,69.356369 
	C372.395111,69.377357 372.640106,69.398346 372.965607,69.683838 
	C373.046112,69.948349 372.713867,70.253983 372.528748,70.383163 
z"
          />
          <path
            fill="#BF2230"
            opacity="1.000000"
            stroke="none"
            d="
M120.526222,67.387199 
	C120.230194,67.128662 120.120354,66.737503 120.010513,66.346352 
	C120.291779,66.360031 120.573036,66.373718 120.951660,66.666321 
	C121.049026,66.945236 120.712410,67.254585 120.526222,67.387199 
z"
          />
          <path
            fill="#FFFDFB"
            opacity="1.000000"
            stroke="none"
            d="
M90.267868,120.548843 
	C92.156578,132.792664 93.919815,144.594864 95.692467,156.460068 
	C73.874580,164.253387 51.860397,161.065414 44.623901,139.004364 
	C42.302605,130.617096 42.422283,122.548996 45.134525,114.615715 
	C49.743980,101.133118 63.581738,93.531197 77.359779,96.791145 
	C84.928314,98.581894 88.745361,102.912071 89.468330,110.641136 
	C89.762871,113.789963 89.921776,116.951477 90.267868,120.548843 
M50.262619,114.983276 
	C45.238605,130.430130 49.066612,145.001999 60.603626,151.287399 
	C55.178547,141.352310 55.279716,131.695328 59.102585,122.248337 
	C63.321964,111.821487 71.808029,107.147209 83.325851,106.708290 
	C79.983047,101.416405 75.130966,100.821449 70.150948,101.047668 
	C61.007805,101.462990 54.553467,106.085190 50.262619,114.983276 
z"
          />
          <path
            fill="#FEFCFB"
            opacity="1.000000"
            stroke="none"
            d="
M294.937195,116.422539 
	C301.169006,101.932167 310.138611,95.494408 322.759644,96.022224 
	C334.354034,96.507095 339.409088,101.708809 340.068665,113.344543 
	C340.368927,118.641830 341.245300,123.906456 341.989929,129.588043 
	C343.554718,138.795273 344.989929,147.600357 346.476166,156.718307 
	C331.774902,161.013641 316.448669,163.800537 303.636902,152.684143 
	C292.970184,143.428925 291.860016,130.261002 294.937195,116.422539 
M309.927856,150.952301 
	C310.182617,150.904358 310.437408,150.856415 310.692169,150.808487 
	C310.476532,150.525284 310.260864,150.242065 309.988647,149.186508 
	C302.046234,132.679871 309.144928,107.595757 333.450562,106.612083 
	C330.163269,101.478676 323.345551,99.531868 315.871033,101.647133 
	C306.376587,104.334030 300.932953,110.950485 299.261353,120.315346 
	C297.157745,132.100296 298.980377,142.915756 309.927856,150.952301 
z"
          />
          <path
            fill="#FFFFFF"
            opacity="1.000000"
            stroke="none"
            d="
M287.796570,245.519623 
	C287.631348,247.623215 287.536346,249.445908 286.892853,251.048355 
	C286.554840,251.890076 285.136169,252.971024 284.419708,252.832870 
	C283.290192,252.615097 281.413239,251.349335 281.488525,250.724182 
	C281.720825,248.795242 282.402618,246.704483 283.581238,245.203964 
	C284.106506,244.535217 286.237091,245.127396 287.796570,245.519623 
z"
          />
          <path
            fill="#BF2230"
            opacity="1.000000"
            stroke="none"
            d="
M50.446136,114.646202 
	C54.553467,106.085190 61.007805,101.462990 70.150948,101.047668 
	C75.130966,100.821449 79.983047,101.416405 83.325851,106.708290 
	C71.808029,107.147209 63.321964,111.821487 59.102585,122.248337 
	C55.279716,131.695328 55.178547,141.352310 60.603626,151.287399 
	C49.066612,145.001999 45.238605,130.430130 50.446136,114.646202 
z"
          />
          <path
            fill="#BF212F"
            opacity="1.000000"
            stroke="none"
            d="
M309.470306,150.629135 
	C298.980377,142.915756 297.157745,132.100296 299.261353,120.315346 
	C300.932953,110.950485 306.376587,104.334030 315.871033,101.647133 
	C323.345551,99.531868 330.163269,101.478676 333.450562,106.612083 
	C309.144928,107.595757 302.046234,132.679871 309.863220,149.731689 
	C309.737762,150.276871 309.470306,150.629135 309.470306,150.629135 
z"
          />
          <path
            fill="#BF212F"
            opacity="1.000000"
            stroke="none"
            d="
M309.891479,150.117859 
	C310.260864,150.242065 310.476532,150.525284 310.692169,150.808472 
	C310.437408,150.856415 310.182617,150.904358 309.699097,150.790710 
	C309.470306,150.629135 309.737762,150.276871 309.891479,150.117859 
z"
          />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} to="/auth/login" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
