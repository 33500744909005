import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
// @mui
import { Button, Checkbox, Link, TableCell, TableRow } from '@mui/material';
// utils
import { fDate } from '../../../../utils/formatTime';
// components
import Label from '../../../../components/label';
import Iconify from '../../../../components/iconify';
import { getColorFromStatus } from '../../../../utils/miscUtils';
import { PATH_DASHBOARD } from '../../../../routes/paths';

// ----------------------------------------------------------------------

InvoiceTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  isMini: PropTypes.bool,
};

export default function InvoiceTableRow({ row, selected, onSelectRow, isMini = false }) {
  const navigate = useNavigate();

  function viewDetail(data) {
    sessionStorage.setItem('customer', JSON.stringify(data));
    window.open(`/dashboard/customer/${data.id}`, '_blank'); // 'rel=noopener noreferrer'
  }

  const viewAgent = () => {
    navigate(PATH_DASHBOARD.agencies.agents.view(row.agentmsisdn));
  };
  return (
    <TableRow hover selected={selected}>
      {isMini ? (
        <TableCell align="left">
          {row.lastname} {row.firstname}
          {row.isVerified && <Iconify icon="material-symbols:verified" color="gold" />}
        </TableCell>
      ) : (
        <>
          <TableCell padding="checkbox">
            <Checkbox checked={selected} onClick={onSelectRow} />
          </TableCell>
          <TableCell align="left">{row.firstname}</TableCell>
          <TableCell align="left">
            {row.lastname}
            {row.isVerified && <Iconify icon="material-symbols:verified" color="gold" />}
          </TableCell>
        </>
      )}

      <TableCell align="left">{row.msisdn}</TableCell>
      <TableCell align="left">{fDate(row.dateadded)}</TableCell>
      <TableCell align="left">
        <Label color={getColorFromStatus(row.subscriptionstatus)}>{row.subscriptionstatus}</Label>
      </TableCell>
      {isMini && (
        <>
          <TableCell align="left">
            <Link
              noWrap
              variant="body2"
              onClick={viewAgent}
              sx={{ color: 'text.disabled', cursor: 'pointer' }}
            >
              {row.agentmsisdn}
            </Link>
          </TableCell>
          <TableCell align="left">{row.customerlocation}</TableCell>
        </>
      )}

      <TableCell align="left">
        <Label color={getColorFromStatus(row.status)}>{row.status}</Label>
      </TableCell>
      <TableCell align="left">{row.channel}</TableCell>
      <TableCell align="right">
        <Button size="small" variant="contained" onClick={() => viewDetail(row)}>
          View
        </Button>
      </TableCell>
    </TableRow>
  );
}
