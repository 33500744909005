import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Divider,
  TableBody,
  Container,
  TableContainer,
  Grid,
  ListItemText,
  CardHeader,
  Link,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// utils
import { fDate, fDateTime } from '../../utils/formatTime';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../components/settings';
import {
  useTable,
  getComparator,
  TableHeadCustom,
  TableEmptyRows,
  TableNoData,
  TablePaginationCustom,
  emptyRows,
  TableSkeleton,
} from '../../components/table';
// sections
import RoleBasedGuard from '../../auth/RoleBasedGuard';
import API from '../../api';
import AgentDashboardAnalytic from '../../sections/@dashboard/agents/dashboard/AgentDashboardAnalytics';
import { SubscriptionsChart } from '../../sections/@dashboard/afa/dashboard';
import SearchTableToolbar from '../../sections/@dashboard/agents/dashboard/TableToolbar';
import { CustomerTableRow } from '../../sections/@dashboard/customers/list';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'msisdn', label: 'Msisdn', alignRight: false },
  { id: 'dateadded', label: 'Date Added', alignRight: false },
  { id: 'subscriptionstatus', label: 'Subscription', alignRight: false },
  { id: 'agentmsisdn', label: 'Registered By', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'channel', label: 'Channel', alignRight: false },
  { id: '' },
];

const role = ['SUPER_ADMIN', 'ADMIN'];

// ----------------------------------------------------------------------

export default function AssociationDetailPage() {
  const theme = useTheme();
  const { themeStretch } = useSettingsContext();

  const { associationId } = useParams();
  const [dashboardData, setDashboardData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchDashboard = async () => {
      setIsLoading(true);
      try {
        API.get(`agencies/associations/${associationId}/dashboard`)
          .then((response) => {
            if (response.data.status === '000') {
              setIsLoading(false);
              setDashboardData(response.data);
            } else {
              // setErrorMessage(response.data.message);
              // setErrorOpen(true);
            }
          })
          .catch(() => {
            // setErrorMessage('Unable to process request. Please try agian or contact system administrator');
            // setErrorOpen(true);
          });
        // setSubscription(subscriptions);
      } catch (e) {
        console.log(e);
        // setSubscription(subscriptions);
      }
    };
    fetchDashboard();
  }, [associationId]);
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'createDate' });

  const [filterName, setFilterName] = useState('');

  const navigate = useNavigate();
  const viewSuperAgent = () => {
    navigate(PATH_DASHBOARD.agencies.superagents.view(dashboardData?.details?.superagentMsisdn));
  };

  const dataFiltered = applyFilter({
    inputData: dashboardData?.members || [],
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const denseHeight = dense ? 56 : 76;

  const isFiltered = filterName !== '';

  const isNotFound = !dataFiltered.length && !!filterName;

  const handleFilterName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleResetFilter = () => {
    setFilterName('');
  };

  const renderOverview = (
    <Stack component={Card} spacing={2} sx={{ p: 3 }}>
      {[
        {
          label: 'Association Name',
          value: dashboardData?.details?.associationName,
          icon: <Iconify icon="ic:baseline-group" />,
        },
        {
          label: 'Contact Person',
          value: `${dashboardData?.details?.contactPerson} - ${dashboardData?.details?.phoneNumber}`,
          icon: <Iconify icon="teenyicons:phone-solid" />,
        },
        {
          label: 'Super Agent',
          value: (
            <Link
              noWrap
              variant="body2"
              onClick={viewSuperAgent}
              sx={{ color: 'text.disabled', cursor: 'pointer' }}
            >
              {dashboardData?.details?.superagentname}
            </Link>
          ),
          icon: <Iconify icon="raphael:parent" />,
        },
        {
          label: 'Location',
          value: dashboardData?.details?.location,
          icon: <Iconify icon="mdi:location" />,
        },
        {
          label: 'Date Added',
          value: fDateTime(dashboardData?.details?.createdAt),
          icon: <Iconify icon="solar:calendar-date-bold" />,
        },
        {
          label: 'Added By',
          value: dashboardData?.details?.addedBy,
          icon: <Iconify icon="material-symbols:person" />,
        },
        {
          label: 'Description',
          value: dashboardData?.details?.description,
          icon: <Iconify icon="material-symbols:description" />,
        },
      ].map((item) => (
        <Stack key={item.label} spacing={1.5} direction="row">
          {item.icon}
          <ListItemText
            primary={item.label}
            secondary={item.value}
            primaryTypographyProps={{
              typography: 'body2',
              color: 'text.secondary',
              mb: 0.5,
            }}
            secondaryTypographyProps={{
              typography: 'subtitle2',
              color: 'text.primary',
              component: 'span',
            }}
          />
        </Stack>
      ))}
    </Stack>
  );

  return (
    <>
      <Helmet>
        <title>Agent Details</title>
      </Helmet>
      <RoleBasedGuard hasContent roles={role}>
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <CustomBreadcrumbs
            heading={dashboardData?.details?.associationName}
            links={[
              {
                name: 'Dashboard',
                href: PATH_DASHBOARD.root,
              },
              {
                name: 'Agencies',
                href: PATH_DASHBOARD.agencies.root,
              },
              {
                name: 'Associations',
                href: PATH_DASHBOARD.agencies.associations.root,
              },
              {
                name: dashboardData?.details?.associationName,
              },
            ]}
          />

          <Card sx={{ mb: 1 }}>
            <Scrollbar>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
                sx={{ py: 2 }}
              >
                <AgentDashboardAnalytic
                  title="Members"
                  total={dashboardData?.details?.totalMembers}
                  percent={100}
                  label="members"
                  icon="ri:registered-fill"
                  color={theme.palette.info.main}
                />

                <AgentDashboardAnalytic
                  title="Active"
                  total={dashboardData?.details?.activeMembers}
                  percent={100}
                  label="members"
                  icon="carbon:face-activated-filled"
                  color={theme.palette.success.main}
                />
                <AgentDashboardAnalytic
                  title="New"
                  total={dashboardData?.details?.newMembers}
                  percent={100}
                  label="members"
                  icon="material-symbols:autorenew-rounded"
                  color={theme.palette.warning.main}
                />
                <AgentDashboardAnalytic
                  title="Agents"
                  total={dashboardData?.details?.totalAgents}
                  percent={100}
                  label="agents"
                  icon="carbon:face-activated-filled"
                  color={theme.palette.secondary.main}
                />
              </Stack>
            </Scrollbar>
          </Card>
          <Grid container spacing={3} sx={{ pt: 4, pb: 4 }}>
            <Grid item xs={12} md={6} lg={4}>
              {renderOverview}
            </Grid>
            <Grid item xs={12} md={12} lg={8}>
              <SubscriptionsChart
                title="Subscriptions Summary"
                subheader="Shows onboarding and transactions breakdown for the last 14 days"
                chart={{
                  labels: dashboardData?.onboardingSummary?.map((e) => fDate(e.date)) || [],
                  series: [
                    {
                      name: 'Registered',
                      type: 'column',
                      fill: 'solid',
                      data: dashboardData?.onboardingSummary?.map((e) => e.totalRegistered) || [],
                    },
                    {
                      name: 'Verified',
                      type: 'line',
                      fill: 'solid',
                      data: dashboardData?.onboardingSummary?.map((e) => e.totalVerified) || [],
                    },
                    {
                      name: 'Rejected',
                      type: 'column',
                      fill: 'solid',
                      data: dashboardData?.onboardingSummary?.map((e) => e.totalRejected) || [],
                    },
                    {
                      name: 'Renewed',
                      type: 'area',
                      fill: 'gradient',
                      data: dashboardData?.subscriptionsSummary?.map((e) => e.totalRenewed) || [],
                    },
                    {
                      name: 'Activated',
                      type: 'column',
                      fill: 'solid',
                      data: dashboardData?.subscriptionsSummary?.map((e) => e.totalActivated) || [],
                    },
                  ],
                  colors: [
                    theme.palette.success.main,
                    theme.palette.info.main,
                    theme.palette.error.main,
                    theme.palette.primary.light,
                    theme.palette.secondary.light,
                  ],
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ pt: 2, pb: 2 }}>
            <Grid item lg={12}>
              <Card variant="outlined">
                <CardHeader
                  title="Association Members"
                  // subheader="All customers registered under this association"
                  sx={{ mb: 1 }}
                />
                <SearchTableToolbar
                  filterName={filterName}
                  onFilterName={handleFilterName}
                  isFiltered={isFiltered}
                  onResetFilter={handleResetFilter}
                  placeholder="Member Search"
                />

                <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                  <Scrollbar>
                    <Table size="small">
                      <TableHeadCustom
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={dashboardData?.members?.length || 0}
                        onSort={onSort}
                      />
                      <TableBody>
                        {(isLoading ? [...Array(rowsPerPage)] : dataFiltered)
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) =>
                            row ? (
                              <CustomerTableRow key={row.id} row={row} isMini />
                            ) : (
                              !isNotFound && (
                                <TableSkeleton key={index} sx={{ height: denseHeight }} />
                              )
                            )
                          )}

                        <TableEmptyRows
                          height={denseHeight}
                          emptyRows={emptyRows(
                            page,
                            rowsPerPage,
                            dashboardData?.onboardingSummary?.length || 0
                          )}
                        />
                        <TableNoData isNotFound={isNotFound} />
                      </TableBody>
                    </Table>
                  </Scrollbar>
                </TableContainer>

                <TablePaginationCustom
                  count={dataFiltered.length}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  dense={dense}
                  onChangeDense={onChangeDense}
                />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </RoleBasedGuard>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filterName }) {
  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (customer) =>
        customer.lastname.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        customer.firstname.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        customer.msisdn.indexOf(filterName) !== -1 ||
        customer.agentmsisdn.indexOf(filterName) !== -1
    );
  }
  return inputData;
}
