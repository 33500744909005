import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, Card, CardContent, CardHeader, Paper, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../../utils/formatNumber';
// components
import Iconify from '../../../../components/iconify';

// ----------------------------------------------------------------------

RegistrationByChannel.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  subheader: PropTypes.string,
};
export default function RegistrationByChannel({ title, subheader, list, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <CardContent>
        <Box display="grid" gap={5} gridTemplateColumns="repeat(5, 1fr)">
          {list.map((site) => (
            <Paper key={site.label} variant="outlined" sx={{ py: 2.5, textAlign: 'center' }}>
              <ChannelIcon channel={site.value} />
              <Typography variant="h6" sx={{ mt: 0.5 }}>
                {fShortenNumber(site.total)}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {site.label}
              </Typography>
            </Paper>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}

ChannelIcon.propTypes = {
  channel: PropTypes.string,
};

function ChannelIcon({ channel }) {
  switch (channel) {
    case 'data':
      return <Iconify icon="iconoir:internet" color="#1877F2" width={32} />;
    case 'nodata':
      return <Iconify icon="ic:round-signal-wifi-connected-no-internet-4" color="indigo" width={32} />;
    case 'attempts':
      return <Iconify icon="mdi:subscriptions" color="#DF3E30" width={32} />;
    case 'customers':
      return <Iconify icon="mdi:subscriber-identity-module" color="green" width={32} />;
    case 'incomplete':
      return <Iconify icon="carbon:incomplete-error" color="#DF3E30" width={32} />;
    default:
      return <Iconify icon="mdi:subscriber-identity-module" color="green" width={32} />;
  }
}
