import axios from 'axios';

const axiosInstance = axios.create({
  // baseURL: `http://95.216.23.17:3710/afa-web-backend/services/v2/`,
  baseURL: `https://www.afamobile.net/afa-web-backend/services/v2/`,
});

// Add a request interceptor
axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = localStorage.getItem('accessToken');
  const lastRequestTime = Date.now();
  sessionStorage.setItem('lastRequestTime', `${lastRequestTime}`);
  return config;
});
export default axiosInstance;
// export default axios.create({
//   // baseURL: `http://95.216.23.17:8080/afa/services/afaonline/`
//   // baseURL: `http://95.216.23.17:8080/adwa/api/`
//   baseURL: `http://95.216.23.17:3710/afa-web-backend/services/v2/`,
// });
// export default axios.create({
//   baseURL: `https://www.afamobile.net/adwa/api/`
// });

// https://95.216.23.17:8181/afa/services/afaonline/getrecentsubscriptions
