import PropTypes from 'prop-types';
import { useState } from 'react';
import { format } from 'date-fns';
// @mui
import { useNavigate } from 'react-router';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
// components
import Iconify from '../../../../components/iconify';
import Scrollbar from '../../../../components/scrollbar';
import MenuPopover from '../../../../components/menu-popover';
import { TableHeadCustom } from '../../../../components/table';
import { PATH_DASHBOARD } from '../../../../routes/paths';

// ----------------------------------------------------------------------

SuspiciousTransactions.propTypes = {
  title: PropTypes.string,
  tableData: PropTypes.array,
  subheader: PropTypes.string,
  tableLabels: PropTypes.array,
};

export default function SuspiciousTransactions({
  title,
  subheader,
  tableLabels,
  tableData,
  ...other
}) {
  const handleClicked = () => {
    setShowAll(!showAll);
  };
  const [showAll, setShowAll] = useState(false);
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} sx={{ mb: 3 }} />

      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar sx={{ minWidth: 720 }}>
          <Table size="small">
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {showAll
                ? tableData &&
                  tableData.map((row) => <SuspiciousTransactionsRow key={row.id} row={row} />)
                : tableData &&
                  tableData
                    .slice(0, 10)
                    .map((row) => <SuspiciousTransactionsRow key={row.id} row={row} />)}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          size="small"
          color="inherit"
          onClick={handleClicked}
          endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
        >
          {showAll ? 'Collapse' : 'View All'}
        </Button>
      </Box>
    </Card>
  );
}

// ----------------------------------------------------------------------

SuspiciousTransactionsRow.propTypes = {
  row: PropTypes.object,
};

function SuspiciousTransactionsRow({ row }) {
  const [openPopover, setOpenPopover] = useState(null);
  const navigate = useNavigate();

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const gotoCustomer = () => {
    handleClosePopover();
    navigate(PATH_DASHBOARD.general.viewcustomer(row.customerId));
  };

  const goToAgent = () => {
    handleClosePopover();
    navigate(PATH_DASHBOARD.agencies.agents.view(row.initiatedby));
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ ml: 2 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {row.customerName}
              </Typography>
              <Typography variant="subtitle2"> {row.customermsisdn}</Typography>
            </Box>
          </Box>
        </TableCell>

        <TableCell>
          <Typography variant="subtitle2">
            {format(new Date(row.subscriptiondate), 'dd MMM yyyy')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {format(new Date(row.subscriptiondate), 'p')}
          </Typography>
        </TableCell>

        <TableCell>{fCurrency(row.total)}</TableCell>
        <TableCell>{row.initiatedby}</TableCell>

        <TableCell>
          <Typography variant="subtitle2">
            {format(new Date(row.dateRegistered), 'dd MMM yyyy')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {format(new Date(row.dateRegistered), 'p')}
          </Typography>
        </TableCell>
        <TableCell>{row.idtype}</TableCell>
        <TableCell>{row.idnumber}</TableCell>
        <TableCell>{row.registeredBy}</TableCell>
        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 180 }}
      >
        <MenuItem onClick={gotoCustomer}>
          <Iconify icon="carbon:view-filled" />
          View Customer
        </MenuItem>

        <MenuItem onClick={goToAgent}>
          <Iconify icon="ion:navigate-circle-outline" />
          Agent Profile
        </MenuItem>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

AvatarIcon.propTypes = {
  icon: PropTypes.string,
};

function AvatarIcon({ icon }) {
  return (
    <Avatar
      sx={{
        width: 48,
        height: 48,
        color: 'text.secondary',
        bgcolor: 'background.neutral',
      }}
    >
      <Iconify icon={icon} width={24} />
    </Avatar>
  );
}

// ----------------------------------------------------------------------

function renderAvatar(category, avatar) {
  if (category === 'Books') {
    return <AvatarIcon icon="eva:book-fill" />;
  }
  if (category === 'Beauty & Health') {
    return <AvatarIcon icon="eva:heart-fill" />;
  }
  return avatar ? (
    <Avatar
      alt={category}
      src={avatar}
      sx={{ width: 48, height: 48, boxShadow: (theme) => theme.customShadows.z8 }}
    />
  ) : null;
}
